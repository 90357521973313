
.javaImg{
    width: 300px;
    height: 150px;
    background-image: url(./images/java.jpg);
    background-repeat: no-repeat;
    background-size: 300px 150px;
}

.cloudImg{
    width: 300px;
    height: 150px;
    background-image: url(./images/aws.jpg);
    background-repeat: no-repeat;
    background-size: 300px 150px;
}


.bigImg{
    width: 300px;
    height: 150px;
    background-image: url(./images/bigdata.png);
    background-repeat: no-repeat;
    background-size: 300px 150px;
    
}

.sfImg{
    width: 300px;
    height: 150px;
    background-image: url(./images/salesforce.jpeg);
    background-repeat: no-repeat;
    background-size: 300px 150px;
}

.netImg{
    width: 300px;
    height: 150px;
    background-image: url(./images/net.jpg);
    background-repeat: no-repeat;
    background-size: 300px 150px;
}
.uiImg{
    width: 300px;
    height: 150px;
    background-image: url("https://raw.githubusercontent.com/creativetimofficial/public-assets/master/logos/react.jpg");
    background-repeat: no-repeat;
    background-size: 300px 150px;
}

.flippy-front{
    padding: 0%;
}

.ttc_background{
    background-image: url(./images/ttc.jpg);
}